import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Toolbar, AppBar, Button, Menu, MenuItem, Avatar, IconButton, Box } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { useStyles } from './style'

export default function AppToolbar () {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)
  const [currentMenuItem, setCurrentMenuItem] = React.useState('');

  const open = Boolean(anchorEl)
  const openMobile = Boolean(mobileMenuOpen)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleMobileMenuOpen = (event) => {
    setMobileMenuOpen(event.currentTarget)
  }
  const handleMobileMenuClose = () => {
    setMobileMenuOpen(null)
  }
  const handleMenuItemClick = (menuItem) => {
    setCurrentMenuItem(menuItem);
    handleMobileMenuClose();
  };

  const isMobileView = window.innerWidth < 900;

  return (
    <AppBar className={classes.appBar}>
      <Box className={classes.navBox}>
        <Toolbar className={classes.toolBar}>
          <Box sx={{display:{xs:'none', md:'flex'}}}>
            <Button
              className={classes.toolBarLinks}
              color='inherit'
              component={RouterLink}
              to='/'
              activeClassName={classes.activeLink}
            >
              Time
            </Button>
            <Button
              className={classes.toolBarLinks}
              color='inherit'
              component={RouterLink}
              to='/clients'
              activeClassName={classes.activeLink}
            >
              Expenses
            </Button>
            <Button
              className={classes.toolBarLinks}
              color='inherit'
              component={RouterLink}
              to='/clients'
              activeClassName={classes.activeLink}
            >
              Projects
            </Button>
            <Button
              className={classes.toolBarLinks}
              color='inherit'
              component={RouterLink}
              to='/clients'
              activeClassName={classes.activeLink}
            >
              Reports
            </Button>
          </Box>
          <Box sx={{display:{xs:'flex', md:'none'}}}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <h1 className={classes.titleMobileMenu}>{currentMenuItem}</h1>
            <Menu className={classes.mobileMenu} open={Boolean(mobileMenuOpen)}>
              <Box className={classes.mobileMenuItensCtn}>
                <Button className={classes.closeBtnMenuMobile} onClick={handleMobileMenuClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ marginRight: '2px' }} viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" aria-hidden="true">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg> Close menu</Button>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleMenuItemClick('Time')}
                  component={RouterLink}
                  to='/'
                >
                  Time
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleMenuItemClick('Expenses')}
                  component={RouterLink}
                  to='/Expenses'
                  >
                    Expenses
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleMenuItemClick('Projects')}
                  component={RouterLink}
                  to='/Projects'
                >
                  Projects
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleMenuItemClick('Reports')}
                  component={RouterLink}
                  to='/Reports'
                >
                  Reports
                </MenuItem>
                <Box className={classes.mobileMenuSignOutItensCtn}>
                  <MenuItem
                    className={`${classes.menuItem} ${classes.signoutBtn}`}
                    onClick={handleMobileMenuClose}
                    component={RouterLink}
                    to='/MyProfile'
                  >
                    My profile
                  </MenuItem>
                  <MenuItem
                    className={`${classes.menuItem} ${classes.signoutBtn} `} 
                    onClick={handleMobileMenuClose}
                    component={RouterLink}
                    to='/MyTimeReport'
                  >
                    My time report
                  </MenuItem>
                  <MenuItem
                    className={`${classes.menuItem} ${classes.signoutBtn}`}
                    onClick={handleMobileMenuClose}
                    component={RouterLink}
                    to='/Notifications'
                  >
                    Notifications
                  </MenuItem>
                  <MenuItem
                    className={`${classes.menuItem} ${classes.signoutBtn} ${classes.borderNone}`}
                    onClick={handleMobileMenuClose}
                    component={RouterLink}
                    to='/SignOut'
                  >
                    Sign out
                  </MenuItem>
              </Box>
              </Box>
            </Menu>
          </Box>
        </Toolbar>
        <Box>
          <Button
            className={classes.toolBarLinks}
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
              Luis
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            className={classes.menu}
            PaperProps={{
              style: {
                backgroundColor: '#fff',
                color: 'black',
                marginTop: 2
              }
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem className={classes.menuItem}>
              <Avatar />
              <div>
                <h2 className={classes.h2}>
                    Luis Pereira Filho
                </h2>
                <p className={classes.p}>
                    DevSquad
                </p>
              </div>
            </MenuItem>
            <MenuItem className={classes.menuItem} component={RouterLink} to='/PATHHHH' onClick={handleClose}>My profile</MenuItem>
            <MenuItem className={classes.menuItem} onClick={handleClose}>My time report</MenuItem>
            <MenuItem className={classes.menuItem} onClick={handleClose}>Notifications</MenuItem>
            <MenuItem className={`${classes.menuItem} ${classes.signoutBtn}`} onClick={handleClose}>Sign out</MenuItem>
          </Menu>
        </Box>
      </Box>
    </AppBar>
  )
}

