import React from 'react'
import { useStyles } from './style'
import { Button, Grid } from '@material-ui/core'

const TimePage = () => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid xs={12} />

      <Grid xs={2} className={classes.addBtnContainer}>
        <Button className={classes.addBtn}>
          <span>+</span>
        </Button>
        <p>Track time</p>
      </Grid>
      <Grid xs={10} />
      <Grid xs={12} />
    </Grid>
  )
}

export default TimePage
