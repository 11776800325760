import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  addBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    '& p': {
      marginTop: 7,
      fontSize: 12
    }
  },
  addBtn: {
    borderRadius: 12,
    fontSize: 62,
    height: 64,
    minWidth: 64,
    backgroundClip: 'border-box',
    backgroundColor: '#188433',
    borderColor: 'transparent!important',
    color: '#fff',
    fontWeight: 300,
    '& span': {
      marginBottom: 5,
      marginLeft: 1
    }
  }
}))
